import axios from 'axios'
import { store } from 'store'
import { clearUser } from 'reducers/user'
import { jwtDecode } from 'jwt-decode'

export const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
})

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      if (error.response.status === 401) {
        store.dispatch(clearUser())
        localStorage.removeItem('token')
        window.location.href = '/'
      } else if (error.response.data && error.response.data.message) {
        error.message = error.response.data.message
      }
    }
    return Promise.reject(error)
  }
)

// export const authInstance = () => {
//   const state = store.getState()
//   const authToken = state.user.token
//   console.log('Auth Token:', authToken)

//   if (authToken) {
//     instance.defaults.headers.common['Authorization'] = `Bearer ${authToken}`
//   } else {
//     delete instance.defaults.headers.common['Authorization']
//   }

//   return instance
// }

export const authInstance = () => {
  const state = store.getState()
  const authToken = state.user.token

  if (authToken) {
    try {
      const decodedToken = jwtDecode(authToken)
      const currentTime = Math.floor(Date.now() / 1000)

      if (decodedToken.exp < currentTime) {
        store.dispatch(clearUser())
        localStorage.removeItem('token')
        window.location.href = '/'
        throw new Error('Token expired')
      }

      instance.defaults.headers.common['Authorization'] = `Bearer ${authToken}`
    } catch (error) {
      console.error('Invalid token:', error)
      store.dispatch(clearUser())
      localStorage.removeItem('token')
      window.location.href = '/'
    }
  } else {
    delete instance.defaults.headers.common['Authorization']
  }

  return instance
}

const getTenants = ({ per_page, page, required }) => {
  return authInstance().get(`/tenants/`, {
    params: {
      per_page,
      page,
      required,
    },
  })
}

const getMembershipUsage = ({ tenantId }) => {
  return authInstance().get(`/tenants/${tenantId}/memberships/usage`)
}
const getTenantById = ({ tenantId }) => {
  return authInstance().get(`/tenants/${tenantId}/`)
}

const authLogin = ({ email, password }) => {
  return instance.post(`/auth/login`, { email, password })
}

const authRegister = ({ email, password, username }) => {
  return instance.post(`/auth/register`, { email, password, username })
}

const rfpSbir = ({
  dateFrom,
  dateTo,
  keywords,
  closed,
  rate,
  tenantId,
  rfpId,
  withDomains = false,
  perPage = 10,
  page = 1,
  required = true,
}) => {
  return authInstance().get(`/rfp/sbir`, {
    params: {
      date_from: dateFrom,
      date_to: dateTo,
      keywords,
      closed,
      rfp_id: rfpId,
      per_page: perPage,
      page,
      with_domains: withDomains,
      tenant_id: tenantId,
      required,
    },
  })
}

const rfpSam = ({
  dateFrom,
  dateTo,
  keywords,
  rate,
  tenantId,
  rfpId,
  solicitationType,
  withDomains,
  perPage,
  page,
  agency,
  naicsCode,
  typeOfSetAside,
  closed = false,
  required,
}) => {
  return authInstance().get(`/rfp/sam`, {
    params: {
      keywords,
      rate,
      date_from: dateFrom,
      date_to: dateTo,
      tenant_id: tenantId,
      with_domains: withDomains,
      solicitation_type: solicitationType,
      rfp_id: rfpId,
      per_page: perPage,
      page,
      agency,
      naics_code: naicsCode,
      type_of_set_aside: typeOfSetAside,
      closed,
      required,
    },
  })
}

const rfpFiles = ({ tenantId, formData }) => {
  return authInstance().post(`/rfp/files`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    params: {
      tenant_id: tenantId,
    },
  })
}

const getRfpFilters = ({ tenantId }) => {
  return instance.get(`/rfp/filters`, { params: { tenant_id: tenantId } })
}

const tenantsRfpsGet = ({ tenantId }) => {
  return authInstance().get(`/tenants/${tenantId}/rfps/`)
}

const postTenantSearchToNewsletter = ({
  rfpId,
  withDomains = false,
  agency,
  naicsCode,
  typeOfSetAside,
  tenantId,
  keywords,
  name,
  required = true,
}) => {
  return authInstance().post(
    '/rfp/register-newsletter',
    {},
    {
      params: {
        rfp_id: rfpId,
        with_domains: withDomains,
        agency,
        naics_code: naicsCode,
        type_of_set_aside: typeOfSetAside,
        tenant_id: tenantId,
        keywords,
        name,
        required,
      },
    }
  )
}
const tenantsNewslettersGet = ({ tenantId }) => {
  return authInstance().get(`/tenants/${tenantId}/newsletters/`)
}

const deleteNewsletters = ({ tenantId, newsletterId }) => {
  return authInstance().delete(
    `/tenants/${tenantId}/newsletters/${newsletterId}`
  )
}

const getMailings = ({ tenantId, required = true }) => {
  return authInstance().get(`/tenants/${tenantId}/mailings/`, {
    params: { required },
  })
}
const postMailings = ({ tenantId, emails, required = true }) => {
  return authInstance().post(
    `/tenants/${tenantId}/mailings/`,
    { emails },
    { params: { required } }
  )
}

const deleteMailings = ({ tenantId, emails, required = true }) => {
  return authInstance().delete(`/tenants/${tenantId}/mailings/`, {
    data: { emails },
    params: { required },
  })
}

const api = {
  auth: {
    login: authLogin,
    register: authRegister,
  },
  rfp: {
    files: rfpFiles,
    sbir: rfpSbir,
    sam: rfpSam,
    getFilters: getRfpFilters,
  },
  tenants: {
    getTenants: getTenants,
    getTenantById: getTenantById,
    getMembershipUsage: getMembershipUsage,
    rfps: {
      get: tenantsRfpsGet,
    },
    newsletters: {
      get: tenantsNewslettersGet,
      delete: deleteNewsletters,
      post: postTenantSearchToNewsletter,
    },
    mailings: {
      get: getMailings,
      post: postMailings,
      delete: deleteMailings,
    },
  },
}

export default api
