import React from 'react'
import { Box, Typography } from '@mui/material'
import { HourglassEmpty } from '@mui/icons-material'

const ComingSoonPage = ({
  title = 'Coming Soon!',
  message = "We're building something amazing. Check back later!",
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        backgroundColor: '#f0f0f0',
        textAlign: 'center',
      }}>
      <HourglassEmpty sx={{ fontSize: 80, color: 'gray', mb: 2 }} />
      <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 2 }}>
        {title}
      </Typography>
      <Typography variant="body1">{message}</Typography>
    </Box>
  )
}

export default ComingSoonPage
