// import React, { useEffect, useState } from 'react'
// import { connect } from 'react-redux'
// import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material'
// import styled from '@emotion/styled'
// import { CloudUpload } from '@mui/icons-material'
// import CopyrightFooter from 'components/CopyrightFooter'

// const UploadCard = (props) => {
//   const [filenames, setFilenames] = useState([])

//   const VisuallyHiddenInput = styled('input')({
//     clip: 'rect(0 0 0 0)',
//     clipPath: 'inset(50%)',
//     height: 1,
//     overflow: 'hidden',
//     position: 'absolute',
//     bottom: 0,
//     left: 0,
//     whiteSpace: 'nowrap',
//     width: 1,
//   })

//   useEffect(() => {
//     let names = []
//     if (props.value) {
//       for (let i = 0; i < props.value.length; i++)
//         names.push(props.value[i].name)

//       setFilenames(names)
//     }
//   }, [props.value])

//   return (
//     <Button
//       component="label"
//       sx={{
//         p: 2,
//         display: 'flex',
//         flexDirection: 'column',
//         justifyContent: 'space-between',
//         alignItems: 'center',
//         height: { xs: 300, md: 500 },
//         borderRadius: 4,
//         borderWidth: 2,
//         borderColor: 'grey.400',
//         backgroundColor: props.value ? '#E0F5E8' : 'background.default',
//         borderStyle: 'dashed',
//         boxShadow: 'none',
//         '&:hover': {
//           borderColor: 'primary.main',
//           backgroundColor: props.value ? '#F0FAF4' : 'background.paper',
//           color: 'primary.main',
//         },
//         '@media (hover: none)': {
//           borderColor: 'primary.main',
//           backgroundColor: props.value ? '#F0FAF4' : 'background.paper',
//           color: 'primary.main',
//         },
//         color: 'grey.700  ',
//       }}
//       {...props}>
//       <VisuallyHiddenInput
//         type="file"
//         onChange={props.onChange}
//         multiple={props.multiple}
//       />
//       <Typography>{props.title}</Typography>
//       <Typography
//         sx={{ color: props.value ? 'black' : 'grey.400' }}
//         align="center"
//         variant="caption">
//         {props.value
//           ? filenames.map((file) => (
//               <span>
//                 {file}
//                 <br />
//               </span>
//             ))
//           : props.description}
//       </Typography>
//       <Box tabIndex={-1} sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
//         <CloudUpload />
//         Upload
//       </Box>
//     </Button>
//   )
// }

// const ProposalBuilderPage = (props) => {
//   const [rfpDocument, setRfpDocument] = useState(null)
//   const [companyData, setCompanyData] = useState(null)
//   const [proposalTemplate, setProposalTemplate] = useState(null)
//   const [proposalExample, setProposalExample] = useState(null)

//   const [loading, setLoading] = useState(false)

//   return (
//     <Box sx={{
//       display: 'flex',
//       flexDirection: 'column',
//       minHeight: '100vh',
//     }}>
//       <Typography
//         variant="h4"
//         textAlign="center"
//         mb={2}
//         sx={{ fontWeight: 'bold', alignSelf: 'flex-start' }}>
//         Proposal Builder
//       </Typography>
//       <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
//         <Grid item xs={12} md={3}>
//           <UploadCard
//             title="RFP Document"
//             description="Upload the RFP document you are responding to."
//             onChange={(e) => setRfpDocument(e.target.files)}
//             value={rfpDocument}
//           />
//         </Grid>
//         <Grid item xs={12} md={3}>
//           <UploadCard
//             multiple
//             title="Company Data"
//             description="Upload any relevant data about your company that can be used in proposals."
//             onChange={(e) => setCompanyData(e.target.files)}
//             value={companyData}
//           />
//         </Grid>
//         <Grid item xs={12} md={3}>
//           <UploadCard
//             title="Proposal Template"
//             description="Upload the template your company uses for proposals."
//             onChange={(e) => setProposalTemplate(e.target.files)}
//             value={proposalTemplate}
//           />
//         </Grid>
//         <Grid item xs={12} md={3}>
//           <UploadCard
//             title="Proposal Example"
//             description="Upload an example of how your company writes a proposal."
//             onChange={(e) => setProposalExample(e.target.files)}
//             value={proposalExample}
//           />
//         </Grid>
//       </Grid>
//       <Box
//         sx={{
//           px: { xs: 0, sm: 8, md: 20, lg: 30 },
//           mt: 4,
//         }}>
//         <Button
//           disabled={
//             !(
//               rfpDocument &&
//               companyData &&
//               proposalTemplate &&
//               proposalExample
//             ) || loading
//           }
//           variant="contained"
//           color="primary"
//           fullWidth
//           sx={{ display: 'flex', gap: 1 }}
//           onClick={() => {
//             setLoading(true)
//             setTimeout(() => {
//               setLoading(false)
//               alert(
//                 'You tried to generate a new proposal outline.  This feature is still in progress.'
//               )
//             }, 1000)
//           }}>
//           {loading && <CircularProgress size={20} color="grey" />}
//           <Box>
//             Generat
//             {loading ? 'ing' : 'e'} Proposal Outline
//           </Box>
//         </Button>
//       </Box>
//       <Box sx={{ flexGrow: 1 }} />
//       <CopyrightFooter/>
//     </Box>

//   )
// }

// const mapDispatchToProps = {}

// export default connect(null, mapDispatchToProps)(ProposalBuilderPage)

import React, { useState } from 'react'
import { connect } from 'react-redux'
import ComingSoonPage from 'components/ComingSoon'

const ProposalBuilderPage = (props) => {
  return <ComingSoonPage title="Proposal Builder" message="Coming Soon!" />
}
const mapDispatchToProps = {}

export default connect(null, mapDispatchToProps)(ProposalBuilderPage)
