// import React from 'react'
// import { connect } from 'react-redux'
// import { Box, TableCell, Typography } from '@mui/material'
// import moment from 'moment'
// import EnhancedTable from 'components/DataTable'
// import CopyrightFooter from 'components/CopyrightFooter'

// const MyProposalsPage = (props) => {
//   const createData = (id, createdAt, title, url) => ({
//     id,
//     createdAt,
//     title,
//     url,
//   })

//   const rows = [
//     createData(1, moment(), 'Proposal Title 1', 'https://www.bigkittylabs.com'),
//     createData(
//       2,
//       moment().subtract(1, 'days'),
//       'Proposal Title 2',
//       'https://www.bigkittylabs.com'
//     ),
//     createData(
//       3,
//       moment().subtract(2, 'days'),
//       'Proposal Title 3',
//       'https://www.bigkittylabs.com'
//     ),
//     createData(
//       4,
//       moment().subtract(3, 'days'),
//       'Proposal Title 4',
//       'https://www.bigkittylabs.com'
//     ),
//     createData(
//       5,
//       moment().subtract(4, 'days'),
//       'Proposal Title 5',
//       'https://www.bigkittylabs.com'
//     ),
//     createData(
//       6,
//       moment().subtract(5, 'days'),
//       'Proposal Title 6',
//       'https://www.bigkittylabs.com'
//     ),
//     createData(
//       7,
//       moment().subtract(6, 'days'),
//       'Proposal Title 7',
//       'https://www.bigkittylabs.com'
//     ),
//     createData(
//       8,
//       moment().subtract(7, 'days'),
//       'Proposal Title 8',
//       'https://www.bigkittylabs.com'
//     ),
//     createData(
//       9,
//       moment().subtract(8, 'days'),
//       'Proposal Title 9',
//       'https://www.bigkittylabs.com'
//     ),
//     createData(
//       10,
//       moment().subtract(9, 'days'),
//       'Proposal Title 10',
//       'https://www.bigkittylabs.com'
//     ),
//     createData(
//       11,
//       moment().subtract(10, 'days'),
//       'Proposal Title 11',
//       'https://www.bigkittylabs.com'
//     ),
//     createData(
//       12,
//       moment().subtract(11, 'days'),
//       'Proposal Title 12',
//       'https://www.bigkittylabs.com'
//     ),
//   ]

//   const rowCells = (row) => (
//     <>
//       <TableCell align="left">
//         {moment(row.createdAt).format('MM/DD/YYYY')}
//       </TableCell>
//       <TableCell align="right">{row.title}</TableCell>
//       <TableCell align="right">{row.url}</TableCell>
//     </>
//   )

//   const headCells = [
//     {
//       id: 'createdAt',
//       numeric: false,
//       disablePadding: true,
//       label: 'Date Created',
//     },
//     {
//       id: 'title',
//       numeric: true,
//       disablePadding: false,
//       label: 'Proposal Title',
//     },
//     {
//       id: 'url',
//       numeric: true,
//       disablePadding: false,
//       label: 'URL',
//     },
//   ]

//   return (
//     <Box
//       sx={{
//         display: 'flex',
//         flexDirection: 'column',
//         minHeight: '100vh',
//       }}>
//       <Typography
//         variant="h4"
//         textAlign="center"
//         mb={2}
//         sx={{ fontWeight: 'bold', alignSelf: 'flex-start' }}>
//         My Proposals
//       </Typography>
//       <EnhancedTable rows={rows} rowCells={rowCells} headCells={headCells} />
//       <Box sx={{ flexGrow: 1 }} />
//       <CopyrightFooter />
//     </Box>
//   )
// }

// const mapDispatchToProps = {}

// export default connect(null, mapDispatchToProps)(MyProposalsPage)

import { connect } from 'react-redux'
import ComingSoonPage from 'components/ComingSoon'

const MyProposalsPage = (props) => {
  return <ComingSoonPage title="My Proposals" message="Coming Soon!" />
}
const mapDispatchToProps = {}

export default connect(null, mapDispatchToProps)(MyProposalsPage)
